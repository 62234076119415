<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <div class="space-between">
          <div>
            <span>业务编号: {{ form.serial_num }}</span>
          </div>
          <div>
            <a-button>
              <router-link :to="{ name: 'WmsWarehouseOffice' }">取消</router-link>
            </a-button>
            <a-divider type="vertical" />
            <a-button type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
          </div>
        </div>
      </div>

      <a-form-model
        layout="vertical"
        :model="form"
        :rules="rules"
        ref="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="付款对象" prop="customer_id">
              <a-select
                showSearch
                allowClear
                :filter-option="filterOption"
                placeholder="选择付款对象"
                v-model="form.customer_id"
              >
                <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="业务费用" prop="business_expenses">
              <a-select
                allowClear
                placeholder="选择业务费用"
                v-model="form.business_expenses"
              >
                <a-select-option v-for="item in businessExpensesList" :key="item.key" :value="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="时间" v-if="form.business_expenses !== '仓储材料费' && form.business_expenses !== '柴油费'">
              <a-range-picker @change="onDateChange"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item v-if="form.business_expenses==='修理费'" label="修理部件" prop="repair_remark" >
              <a-input v-model="form.repair_remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-textarea v-model="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-card class="card" title="其他信息" :bordered="false">
      <a-tabs :activeKey="activeKey" @change="key => { activeKey = key }">
        <a-tab-pane tab="费用信息" key="1">
          <edit-table
            :columns="feeColumns"
            :sourceData="feeData"
            @getTableDate="getFeeData" />
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import { getCommonOptions, getSupplier, initOfficeBusinessExpenses } from '@/api/common';
import { EditTable } from '@/components';
import preventReClick from '@/utils/preventReClick';
import { saveOrUpdateWmsWarehouseOffice, getWmsWarehouseOfficeDetailById } from '@/api/wms';
import { getFirstLetter } from '@/utils/util'

export default {
  components: {
    EditTable, preventReClick
  },
  data() {
    return {
      form: {},
      rules: {
        customer_id: [{ required: true, message: '请选择付款对象', trigger: 'blur' }],
        business_expenses: [{ required: true, message: '请选择业务费用', trigger: 'blur' }]
      },
      supplierOps: [],
      activeKey: '1',
      feeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 1, type: 2 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      feeData: [],
      disabled: false,
      isEdit: false,
      businessExpensesList: []
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false;
      if (!this.queryParam && newRoute.name === 'WmsWarehouseOfficeCUR') {
        // 编辑页面由监控
        this.handleFormReset();
        if (newRoute.params.id === 'create') {
          // 新增
          this.isEdit = false;
          this.$route.meta.title = '新增仓储行政';
        } else {
          // 编辑
          this.isEdit = true;
          this.$route.meta.title = '编辑仓储行政';
          this.disabled = newRoute.query.disabled;
          this.getEditData(newRoute.params.id);
        }
      }
    }
  },
  mounted() {
    initOfficeBusinessExpenses().then(res => {
      this.businessExpensesList = res.OFFICE_BUSINESS_EXPENSES_TYPE_LIST.filter(item => item.department === 'WMS')
    })
    if (this.$route.params.id !== 'create') {
      this.isEdit = true;
      this.$route.meta.title = '编辑仓储行政';
      this.disabled = this.$route.query.disabled;
      this.getEditData(this.$route.params.id);
    }
  },
  created() {
    this.handleFormReset();
    getSupplier({ department: 1, excludeBlack: 'Y' }).then(res => {
      this.supplierOps = res
    })
  },
  methods: {
    getCommonOptions,
    getSupplier,
    handleFormReset() {
      this.form = {};
      this.feeData = [];
    },
    getEditData(id) {
      if (id) {
        getWmsWarehouseOfficeDetailById({ id: id }).then(v => {
          this.form = v;
          this.feeData = v.fee_info_list ? v.fee_info_list.map(fee => {
            if (fee.fee_status > 2 && fee.fee_status !== 4) {
              fee.disabled = true;
            } else {
              fee.disabled = false;
            }
            return {
              id: fee.id,
              fee_id: fee.fee_id,
              pay_target: fee.pay_target,
              amount: fee.amount,
              disabled: fee.disabled,
              fee_status: fee.fee_status
            }
          }) : []
        });
      }
    },
    getFeeData(data) {
      this.feeData = data
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          for (const item of this.feeData) {
            if (item.fee_status === '') {
              item.fee_status = null
            }
          }
          // 构建请求参数
          const data = {
            id: this.form.id,
            customer_id: this.form.customer_id,
            remark: this.form.remark,
            order_start_date: this.form.order_start_date,
            order_end_date: this.form.order_end_date,
            business_expenses: this.form.business_expenses,
            repair_remark: this.form.repair_remark,
            fee_info_list: this.feeData
          }
          // 发送请求
          saveOrUpdateWmsWarehouseOffice(data).then(res => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            });
            this.$router.push({
              name: 'WmsWarehouseOffice',
              query: {
                isEdit: this.isEdit
              }
            });
          });
        }
      })
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].valueOf()
        const endDate = date[1].valueOf()
        this.form['order_start_date'] = startDate
        this.form['order_end_date'] = endDate
      } else {
        this.form['order_start_date'] = null
        this.form['order_end_date'] = null
      }
    }
  }
}
</script>

<style scoped>
  .space-between {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
